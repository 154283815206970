<template>
  <div style="max-width: 720px; margin:0 auto;">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form label-width="80px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="时间:" prop="createDate" style="float:right;">
            {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
          </el-form-item>
          <el-form-item label="单号:" style="float:right;">
            {{ opts.billCode || '' }}
          </el-form-item>
          <div style="clear: both;"></div>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="forms" :model="forms" :rules="rules" class="form-inline" label-position="right">
      <el-row>
        <el-col :xs="24">
          <el-form-item label="请假类型" prop="dataType">
            <el-select v-model="forms.dataType" placeholder="请选择请假类型" :disabled="!startNode">
              <el-option label="调休" value="调休" />
              <el-option label="事假" value="事假" />
              <el-option label="病假" value="病假" />
              <el-option label="产假" value="产假" />
              <el-option label="陪产假" value="陪产假" />
              <el-option label="婚假" value="婚假" />
              <el-option label="丧假" value="丧假" />
              <el-option label="工伤假" value="工伤假" />
              <el-option label="其他" value="其他" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="12">
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker v-model="forms.startDate" type="date" :disabled-date="disabledStartDate"  :disabled="!startNode"
              @blur="dateChange()" />
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item label="开始时间" prop="startTime">
            <el-time-select v-model="forms.startTime" start="09:30" step="00:30" end="18:00" style="max-width:120px"  :disabled="!startNode" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="12">
          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker v-model="forms.endDate" type="date" :disabled-date="disabledEndDate" @blur="dateChange()"  :disabled="!startNode" />
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item label="结束时间" prop="endTime">
            <el-time-select v-model="forms.endTime" start="09:30" step="00:30" end="18:00" style="max-width:120px"  :disabled="!startNode" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="请假时长" prop="totalHour">
        <el-input-number v-model="forms.totalHour" :min="1" step="0.5" step-strictly :max="1000" style="max-width:150px;"
          placeholder="0.00"  :disabled="!startNode"></el-input-number>  &nbsp;&nbsp;&nbsp;(单位小时h)
      </el-form-item>
      <el-form-item label="请假事由" prop="reasons">
        <el-input v-model="forms.reasons"  :disabled="!startNode" />
      </el-form-item>
      <el-form-item  class="no-print" label="备注">
        <b>调休</b>
        <p style="width:100%;">以半天为单位，上午2.5小时/下午5小时的原则申请调休，调休请假的同事需提前一天前向直属领导申请，并且在系统申请调休流程；</p>
        <b>事假</b>
        <p style="width:100%;">以至少1小时为计算单位，扣除对应薪资。</p>
        <b>病假</b>
        <p style="width:100%;">提交相关医院休假证明后按深圳市劳动法规定计算薪资</p>
      </el-form-item>
    </el-form>
  </div>
</template>
      
<script>
export default {
  name: "hrleave",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'QJSQ',
        formCode: 'hrleave',
        formName: '请假申请单',
        flowCode: 'hr-leave',
        status: 0,
      },
      forms: this.formdata,
      rules: {
        dataType: [
          { required: true, message: "请选择请假单类型", trigger: "blur" },
        ],
        startDate: [
          { required: true, message: "请选择要请假的开始日期", trigger: "blur" }
        ],
        endDate: [
          { required: true, message: "请选择要请假的结束日期", trigger: "blur" }
        ],
        startTime: [
          { required: true, message: "请选择要请假的开始时间", trigger: "blur" }
        ],
        endTime: [
          { required: true, message: "请选择要请假的结束时间", trigger: "blur" }
        ],
        totalHour: [
          { required: true, message: "请选择要请假的小时数", trigger: "blur" }
        ],
        reasons: [
          { required: true, message: "请输入请假的事由", trigger: "blur" }
        ]
      },
      dict: {},
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: { details: [{}, {}, {}] },
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    async formCheck() {
      let p = this.$refs['forms'].validate()
      try {
        await p
      } catch (error) {
        //此处的error为表单校验失败的字段
        if (error) return false;
      }
      console.log(p)

      return true;

      // this.$refs.formRef.validate((valid) => {
      //   if(valid) return true;
      // });
      // let p = this.$refs.formRef.validate();

      // return false;

      // return new Promise((resolve, reject) => {
      //   this.$refs['formRef'].validate((valid) => {
      //     if (valid) {
      //       resolve(true)
      //     } else {
      //       resolve(false)
      //     }
      //   })
      // })

    },
    getForms() {
      return this.forms;
    },
    removeItem(index) {
      this.forms.details.splice(index, 1);
      console.log(this.forms.details)
    },

    // 设置开始日期 的范围
    disabledStartDate(time) {
      // 只能选择当前时间的前一个月的和当天之前的
      let nowDate = new Date();
      //35天前
      let startRange = new Date().setTime(nowDate.getTime() - (24 * 60 * 60 * 1000 * 31));
      //15天后
      let endRange = this.forms.endDate || new Date().setTime(nowDate.getTime() + (24 * 60 * 60 * 1000 * 15));
      return (
        time.getTime() < startRange || time.getTime() > endRange
      )
    },
    // 设置结束日期 的范围
    disabledEndDate(time) {
      let nowDate = new Date();
      let startRange = this.forms.startDate || new Date().setTime(nowDate.getTime() - (24 * 60 * 60 * 1000 * 31));
      let endRange = new Date().setTime(nowDate.getTime() + (24 * 60 * 60 * 1000 * 25));
      return (
        time.getTime() < startRange || time.getTime() > endRange
      )
    },
    dateChange() {
      // console.log(this.forms.startDate, this.forms.endDate)
      // if (this.forms.startDate && this.forms.endDate) {
      //   //this.$util.fmtDate(this.forms.startDate, "yyyy-MM-dd ")

      // }

      // if (this.forms.startDate) {
      //   let baseStartDate= new Date(this.$util.fmtDate(this.forms.startDate, "yyyy-MM-dd 09:30:00"));
      //   let baseEndDate= new Date(this.$util.fmtDate(this.forms.startDate, "yyyy-MM-dd 18:00:00"));
      //   ////周六的下班时间
      //   //let baseSatEndDate= new Date(this.$util.fmtDate(this.forms.startDate, "yyyy-MM-dd 12:00:00"));
      //   var minu = this.forms.startDate.getMinutes();

      // }
    },
    //规整用户选择的时间 例如15:36变成16:00  09:01变成09:30 以30分钟为维度.
    //时间范围是  9:30-18:00 一天7小时,
    //调休时间以半天为单位，上午 2.5小时/下午5小时的原则申请调休.
    // adjustMinute(date) {
    // }
  },
  created() {
    let that = this;

    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

  },
  watch: {
    formdata: function (newValue, oldValue) {
      console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '') {
        return true;
      }
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50) {
        return true;
      }
      return false;
    }
  }

};
</script>
      
<style scoped></style>
      